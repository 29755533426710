import { Link } from "gatsby";
import * as React from "react";

import { Layout } from "../components/Layout";
import { Page } from "../components/Page";
import { SEO } from "../components/SEO";

import { main, content } from "../styles/pages/404.module.scss";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />

    <Page id="404" className={main}>
      <div className={content}>
        This page does not exist!
        <br />
        <Link to="/">Go back to the main site</Link>
      </div>
    </Page>
  </Layout>
);

export default NotFoundPage;
